// Custom GER variables
$masters-color-blue : #0E4AB3;
$theme-blue : #0E4AB3;
$theme-darkblue: #052B6D;
$theme-light-blue: #5377B5;
$theme-gray: #a1a1a1;
$theme-gray-light: #EFEFEF;
$theme-gray-dark: #707070;
$theme-red : #EF6A65;

$table-data-color: #707070;
$search-color:#a0a0a0;
$theme-blurish-grey:#ADB2BA;

$border-radius-1: 0.25rem;
$border-radius-2: 0.50rem;

$theme-table:#707070;
$radio-green: #C3DA8C;
$theme-font-size:11px;

$font-roboto: Roboto, sans-serif;

// font-size
$font-size-default: 0.67rem; //11px
$font-size-large: 0.875rem; // 14px
$font-size-medium: 0.67rem; //11px
$font-size-small: 0.5rem; // 8px
