/* You can add global styles to this file, and also import other style files */
@import "./assets/scss/app.scss";
@import "variables";

@font-face {
  font-family: "oswald";
  src: local("oswald"), url(./assets/fonts/Oswald/static/Oswald-Regular.ttf) format("truetype");
}

.router-wrapper {
  position: relative;
  overflow: hidden;
  width: 100vw;
  height: calc(100% - 47px);
  perspective: 1200px;
  transform-style: preserve-3d;
}

:host {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  background: #60d7a9;
}

.fa-bell-o {
  color: #808080 !important;
}

.subhead-blue {
  color: $theme-blue !important;
}

.on-hover:hover {
  color: $theme-blue !important;
}

.bg-darkblue {
  background-color: $theme-blue !important;
}

.text-dark {
  color: #949597 !important;
}

.text-blurish-grey {
  color: $theme-blurish-grey !important;
}

.color-darkblue {
  color: $theme-blue !important;
}
.color-blue {
  color: $theme-blue !important;
}

.cursor-pointer {
  cursor: pointer;
}

.nav-tabs,
.nav-pills {
  margin-bottom: 0 !important;
}

.nav-tabs {
  border-bottom: 0;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  border: 1px solid $theme-blue;
  border-radius: 10px;
  color: white;
  text-align: center;
  background-color: $theme-blue;
}

.nav-tabs .nav-link {
  color: #a1a1a1;
  text-align: center;
  border: none;
  font-family: oswald;
  letter-spacing: 0;
}

ul[role="tablist"] li {
  width: 15.9rem;
}

ul[role="tablist"] {
  border-bottom: 1px solid #a1a1a1;
  border-radius: 10px;
  box-shadow: 0 1px #a1a1a1;
}

.nav-link {
  display: block;
  padding: 0.3rem 1rem;
}

//sweet alert
.swal2-popup {
  width: auto !important;

  .swal2-header {
    padding: 0 1em !important;

    h2.swal2-title {
      font-size: 16px !important;
      font-weight: 100 !important;
    }
  }

  .swal2-actions {

    .swal2-confirm.swal2-styled,
    .swal2-cancel.swal2-styled {
      padding: 0.25em 0.75rem !important;
      font-size: 12px !important;
    }

    .swal2-confirm.swal2-styled {
      background-color: $theme-blue !important;
    }

    .swal2-cancel.swal2-styled {
      background-color: #d33 !important;
    }
  }
}

//dashboard calender css
.dashboard-datepicker {
  ngb-datepicker {
    width: auto;
    border: 1px solid $theme-blue !important;
    border-radius: 30px !important;
    padding: 0.75rem;
  }

  .ngb-dp-header {
    border-radius: 30px !important;
  }

  .ngb-dp-months {
    justify-content: center;
  }

  // .custom-select {
  //   margin-top: 0;
  //   color:  $theme-blue !important;
  //   font-weight: 500 !important;
  // }
  .ngb-dp-weekday {
    color: $theme-blue !important;
    font-weight: 800 !important;
    font-style: normal !important;
  }

  .ngb-dp-arrow {
    padding-top: 0.25rem !important;
    justify-content: flex-end !important;
  }

  .ngb-dp-arrow.right {
    justify-content: flex-start !important;
  }

  .ngb-dp-arrow-btn {
    padding: 0.25rem;
    width: 25px;
    height: 25px;
    margin: 0 2rem 0 2rem !important;
    border: 1px solid $theme-blue;
    border-radius: 10px;
    background-color: $theme-blue !important;
    color: white !important;
  }

  .ngb-dp-navigation-chevron {
    position: relative;
    bottom: 3px;
    width: 0.5em !important;
    height: 0.5em !important;
    color: white !important;
  }

  .ngb-dp-day,
  .ngb-dp-week-number,
  .ngb-dp-weekday {
    width: 1.5rem !important;
    height: 1.5rem !important;
  }

  .ngb-dp-month-name {
    margin: 0 0.5rem 0 0.5rem !important;
    max-width: 8rem !important;
    min-width: 8rem !important;
    color: $theme-blue;
    font-size: 12px !important;
    font-weight: 600;
  }

  .btn-light:not([disabled]):not(.disabled).active:hover,
  .btn-light:not([disabled]):not(.disabled).active.active {
    background-color: $theme-blue !important;
    color: white !important;
  }

  .ngb-dp-weekdays {
    font-size: 12px !important;
    border-bottom: 0 !important;
  }

  [ngbDatepickerDayView] {
    width: 22px !important;
    height: 22px !important;
    line-height: 22px !important;
  }

  .ngb-dp-month:first-child .ngb-dp-week {
    column-gap: 1rem !important;
    text-align: center;
    color: #ccc;

    .currentMonthDay {
      color: $theme-table;
    }
  }

  .ngb-dp-day {
    position: relative;
    border-radius: 3px;

    &:active,
    &:hover,
    &.selected {
      background-color: $theme-blue !important;

      .currentMonthDay {
        color: white !important;
      }
    }

    &.ngb-dp-today {
      background-color: #707070;

      .currentMonthDay {
        color: white !important;
      }
    }

    .event-day {
      display: block;
      position: absolute;
      bottom: 1px;
      left: 0;
      right: 0;
      margin: 0 auto;
      padding: 0;
      border-radius: 50%;
      width: 5px;
      height: 5px;
      background-color: #ff7d00;

      &.hidden {
        display: none;
      }
    }
  }

  // .ngb-dp-day:active {
  //   background-color: $theme-blue !important;
  //   color: white !important;
  //   border-radius: 3px;
  // }
  // .ngb-dp-day:hover {
  //   background-color: $theme-blue !important;
  //   color: white !important;
  //   border-radius: 3px;

  //   .currentMonthDay{
  //     color: white !important;
  //   }
  // }
  // .ngb-dp-day:focus {
  //   background-color: $theme-blue !important;
  //   color: white !important;
  //   border-radius: 3px;
  // }
  // .ngb-dp-today {
  //   background-color: #707070;
  //   color: white;
  //   border-radius: 3px;

  //   .currentMonthDay{
  //     color: white !important;
  //   }
  // }

  // .ngb-dp-day[tabindex="0"]{      
  //   background-color: #707070;
  //   .currentMonthDay{
  //     color: white !important;
  //   } 
  // } 




  // .weekend {
  //   background-color: #ff7d00;
  //   border-radius: 50%;
  // }
  .hidden {
    display: none;
  }
}

//dashboard calender css

//Range calender css
.range-datepicker {
  ngb-datepicker {
    border: 1px solid $theme-blue !important;
    border-radius: 30px !important;
    padding: 1rem 0 1rem 0 !important;
  }

  .ngb-dp-header {
    border-radius: 30px !important;
  }

  .ngb-dp-months {
    justify-content: center !important;
  }

  .ngb-dp-weekday {
    color: $theme-blue !important;
    font-weight: 800 !important;
    font-style: normal !important;
  }

  .ngb-dp-arrow {
    padding-top: 0.25rem !important;
    justify-content: flex-end !important;
  }

  .ngb-dp-arrow.right {
    justify-content: flex-start !important;
  }

  .ngb-dp-arrow-btn {
    padding: 0.25rem;
    width: 25px !important;
    height: 25px !important;
    margin: 0 1rem 0 1rem !important;
    border: 1px solid $theme-blue;
    border-radius: 10px !important;
    background-color: $theme-blue !important;
    color: white !important;
  }

  .ngb-dp-navigation-chevron {
    position: relative;
    bottom: 3px;
    width: 0.5em !important;
    height: 0.5em !important;
    color: white !important;
  }

  .ngb-dp-day,
  .ngb-dp-week-number,
  .ngb-dp-weekday {
    width: 1.5rem !important;
    height: 1.5rem !important;
  }

  .ngb-dp-month-name {
    margin: auto !important;
    max-width: 8rem !important;
    min-width: 8rem !important;
    color: $theme-blue !important;
    font-size: 12px !important;
    font-weight: 600 !important;
  }

  .btn-light:not([disabled]):not(.disabled).active:hover,
  .btn-light:not([disabled]):not(.disabled).active.active {
    background-color: $theme-blue !important;
    color: white !important;
  }

  .ngb-dp-weekdays {
    font-size: 12px !important;
    border-bottom: 0 !important;
  }

  .ngb-dp-navigation-select {
    display: flex !important;
    flex: 1 1 6rem;
  }

  .ngb-dp-today {
    background-color: $theme-blue !important;
    color: #fff !important;
    border-radius: 3px !important;
  }

  .ngb-dp-month:first-child .ngb-dp-week {
    text-align: center !important;
    color: $theme-table !important;
  }

  .custom-day {
    text-align: center;
    display: inline-block;
    border-radius: 3px;
    height: 1.5rem !important;
    width: 1.5rem !important;
    font-size: $theme-font-size;
    color: $theme-table;
  }

  .custom-day:hover {
    background-color: $theme-blue !important;
    color: white !important;
    border-radius: 3px !important;
  }

  .custom-day.focused {
    background-color: $theme-blue !important;
    color: white !important;
    border-radius: 3px !important;
    height: 1.5rem !important;
    width: 1.5rem !important;
  }

  .custom-day.range {
    background-color: $theme-blue;
    color: white;
  }

  .custom-day.faded {
    background-color: $theme-blue;
  }

  .custom-select {
    margin: 3px !important;
  }
}

//font-family
.f-family-oswald {
  font-family: oswald;
  letter-spacing: 0.03rem;
}

.roboto-font {
  font-family: Roboto !important;
}

//add-applicant
.add-applicant-form hr {
  border: none !important;
  border-bottom: 1px solid $theme-blue !important;
  margin: 0% !important;
}

.add-applicant-form {
  .immigration-hr {
    border: none !important;
    border-bottom: 1px solid $theme-gray !important;
    margin: 0% !important;
  }
}

select {
  color: $theme-blue;
}

//responsive css

.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper.close_icon {
  width: 6rem;
  height: 100%;
}

.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper.close_icon .logo-wrapper {
  display: flex !important;
  padding: 0.5rem !important;
}

.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper.close_icon .logo-wrapper .a .img {
  padding-left: 0.5rem !important;
}

input[type="search"]::-webkit-search-decoration:hover,
input[type="search"]::-webkit-search-cancel-button:hover {
  cursor: pointer;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: transparent !important;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

//***Modal***
.modal-content {
  border-radius: 1rem;
  padding: 1rem;

  .modal-header {
    padding: 0;
    border-bottom: 1px solid $theme-blue;

    .modal-title {
      color: $theme-blue;
      font-size: 12px;
      font-weight: 500;
      padding-bottom: 0.5rem;
    }

    .modal-close {
      &.position-initial {
        position: initial !important;
      }

      position: absolute !important;
      top: 13px !important;
      right: 10px !important;
      background-color: transparent !important;
      border: 0 !important;
    }
  }

  .modal-body {
    .form-label {
      font-size: 12px;
      color: $theme-blue;
      margin-bottom: 0.75rem;
      display: block;
    }

    .form-control {
      font-size: 12px;
      padding: 0.25rem 0.5rem;
      margin-top: 0.25rem;
    }
  }

  .modal-footer {
    border-top: none;
    padding: 0;

    >* {
      margin: 0;
    }

    button {
      border: none;
      border-radius: 0.5rem;
      padding: 0.25rem 1.75rem;
      font-size: 12px;

      &.btn-save {
        background-color: $theme-blue;
      }
    }
  }
}

//***/Modal***

//***Add-Applicant-Preview***
.modal-content {
  border: 2px solid $theme-blue !important;

  .modal-body {
    .add-applicant-preview {
      .card .card-body {
        padding: 0 !important;
        background-color: transparent;
      }
    }
  }
}

//***/Add-Applicant-Preview***

//***Form***
.password-showhide-control {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  margin-top: 0.25rem;

  .form-control {
    margin-top: 0 !important;
  }

  .btn-showHide {
    position: absolute;
    border: none;
    background-color: transparent;
    margin-right: 5px;
    font-size: 0.6rem;
    line-height: normal;
  }
}

//***/Form***

hr {
  border: none !important;
  border-bottom: 1px solid $theme-blue !important;
  margin: 0% !important;
}

//***edit button user-management***
.btn-dark {
  background-color: #a0a0a0 !important;
  border: none !important;
  line-height: 1 !important;
  font-size: 10px;
  padding: 3px 7px;
}

//***edit applicant***
.btn-edit {
  background-color: #a0a0a0;
  border: none;
  line-height: 1;
  font-size: 12px;
  padding: 5px 10px;
}

.btn-modal {
  border: none !important;
  font-family: $font-roboto !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  padding: 0.375rem 1.75rem !important;
}

.btn-gray {
  background-color: $theme-gray;
  color: #fff;

  &:hover {
    outline: 1px solid $theme-gray;
  }
}

.btn-blue {
  background-color: $theme-blue;
  color: #fff;

  &:hover {
    outline: 1px solid $theme-blue;
  }
}

.btn-red {
  background-color: $theme-red;
  color: #fff;

  &:hover {
    outline: 1px solid $theme-red;
  }
}

// card top border radius remove//
.border-radius-top-0 {
  border-radius: 0 0 15px 15px;
}

//button-small
.button-small {
  background-color: $theme-blue;
  color: #fff !important;
  border-radius: 0.25rem !important;
  padding: 0.25rem 1rem !important;
  border: none !important;
  display: flex !important;
  justify-content: center !important;
  align-content: center !important;
  font-size: $theme-font-size !important;

  svg {
    vertical-align: middle !important;
    align-self: center !important;
    margin-right: 0.25rem !important;
  }

  span {
    font-weight: 600;
    font-family: "Roboto";
  }
}

//button-small

//button-large
.button-large {
  background-color: $theme-blue;
  color: #fff;
  border-radius: 0.5rem;
  padding: 0.25rem 1.75rem;
  border: none;
  display: flex;
  justify-content: center;
  align-content: center;
  font-size: 12px;
}

.btn-EDIT {
  background-color: $theme-gray;
}

//button-large

// toast messages
.toast-container .toast-error {
  background-color: #d33 !important;
}

//***custom ngx datatable component***
.ngxdt {
  display: flex;
  flex-direction: column;
  margin-top: 0.75rem;

  >* {
    font-family: $font-roboto;
  }

  .ngxdt-header {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    row-gap: 45px;

    .ngxdt-buttons {
      align-self: center !important;

      button {
        color: #fff !important;
        border-radius: 0.25rem !important;
        padding: 0.25rem 1rem !important;
        border: none !important;
        display: flex !important;
        justify-content: center !important;
        align-content: center !important;
        font-size: $theme-font-size !important;

        svg {
          vertical-align: middle !important;
          align-self: center !important;
          margin-right: 0.25rem !important;
        }

        span {
          font-weight: 600;
          font-family: "Roboto";
        }

        &.btn-add {
          background-color: $theme-blue;
          line-height: 1.6;

          .fa {
            line-height: 1.6;
            height: 16px;
          }
        }
      }
    }

    .ngxdt-search {
      .ngxdt-search-wrapper {
        width: 300px;
        display: inline-flex;
        outline: 1px solid $search-color;
        margin: 0 1rem;
        align-items: center;
        height: calc(100% - 2px);
        padding: 0;
        border-radius: $border-radius-1;
        font-size: 0.75rem;

        i {
          padding: 0 10px;
          color: $search-color;
          flex: 0 0 auto;
        }

        input {
          border: none;
          height: calc(100% - 4px);
          margin: 0;
          padding: 0;
          color: $search-color;
          flex: 1 1 auto;
          width: max-content;
        }

        button {
          margin: 0;
          padding: 0.25rem 0;
          background-color: transparent;
          outline: none;
          height: 100%;
          border: none;
          border-radius: 0 $border-radius-1 $border-radius-1 0;
          flex: 0 0 auto;
          width: max-content;

          i {
            color: $search-color;
          }

          &:hover {
            background-color: $search-color;

            i {
              color: white;
            }
          }
        }
      }
    }

    .ngxdt-reports-button {
      margin: auto 40px;

      .btn-download {
        background-color: $theme-blue;
        color: #fff;
        font-size: $theme-font-size;
        // margin-right: 1rem;
        border-radius: 0.3rem;
        border: none;
        height: 24px !important;
        width: 24px !important;
      }

      // .btn-search{
      //   background-color: transparent;
      //   color: $theme-table;
      //   font-size: $theme-font-size;
      //   border-radius: 0.30rem;
      // }
    }

    .ngxdt-select {
      display: flex;
      height: 24px;
      color: $table-data-color;
      font-size: 0.75rem;
      font-family: $font-roboto;
      flex: 1 1 auto;
      margin: auto 40px;

      label {
        margin: 0;
        align-self: center;
        margin-right: 0.25rem;
        white-space: nowrap;
      }

      select {
        height: 100%;
        width: fit-content;
        border-radius: $border-radius-1;
        padding: 0 5px;
        margin-right: 2rem;
        // outline: 1px solid $search-color;
        border: none;
        font-weight: 600;
      }

      input {
        height: 100%;
        font-size: 11px;
        width: fit-content;
        color: $theme-table;
        padding: 0 5px;
        margin: 0;
        border: none;
        font-weight: normal;
      }

      .input-activity-log {
        height: 100%;
        border-radius: $border-radius-1;
        font-size: 11px;
        width: fit-content;
        color: $theme-table;
        padding: 0 5px;
        margin-right: 2rem;
        outline: 1px solid $search-color;
        font-weight: normal;
      }
    }
  }

  .ngxdt-body {
    padding: 0.5rem 0 0 0;

    >* {
      font-size: 11px;
      letter-spacing: initial;
      color: $table-data-color;
    }
  }
}

//***/custom ngx datatable component***

//***ngx datatable***
.ngx-datatable.bootstrap {
  // variables
  $row-height: 30px;
  $cell-padding: 0 0.75rem;
  $border-color: #CECECE;


  min-height: calc(100vh - 200px);

  .datatable-header {
    width: 100% !important;

    .datatable-header-inner {
      font-weight: 600;
      color: $theme-blue;
      border-bottom: 2px solid $border-color;
      width: 100% !important;

      overflow-x: auto;

      &::-webkit-scrollbar {
        width: 0;
        height: 0;
        opacity: 0;
        visibility: hidden;
      }
    }

    .datatable-header-cell {
      padding: $cell-padding;
      border: none;

      .datatable-header-cell-label {
        font-size: 12px;
      }

      // to display first column data in center
      &.make-center .datatable-header-cell-template-wrap {
        display: block;
        text-align: center;
      }
    }
  }

  .datatable-body {
    width: 100% !important;

    /* width */
    &::-webkit-scrollbar {
      width: 4px;
      height: 7px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #888;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }

    .datatable-scroll {
      display: initial;
      overflow-y: auto;
      overflow-x: auto;
      height: calc(100vh - 260px);
      width: 100% !important;

      .datatable-row-wrapper {
        min-width: 100% !important;
        width: max-content !important;
        display: table-caption !important;
      }
    }

    .empty-row {
      color: #6c757d;
      text-align: center;
    }


    .datatable-body-row {
      vertical-align: top;
      border-top: none;
      border-bottom: 1px solid $border-color;
      cursor: default !important;
      background-color: #fff !important;
      min-width: 100% !important;

      &.active {
        background-color: rgba(239, 239, 239, 0.4) !important;
        color: $theme-table !important;
      }

      &:hover,
      &[ng-reflect-is-selected="true"]:hover {
        background-color: rgba(239, 239, 239, 0.4) !important;
        color: $theme-table !important;
      }

      // &:hover, &.active {
      // margin: 1px 0 1px 0;
      //   background-color: $theme-blue !important;
      //   color: #fff;
      // }
      // .datatable-row-center{
      //   max-width: 100% !important;
      // }

      .datatable-body-cell {
        padding: $cell-padding;
        line-height: $row-height;

        .btn-edit {
          background-color: $search-color;
          border: none;
          line-height: 1;
          font-size: 10px;
          padding: 3px 7px;
          margin-top: -3px;

          &:hover {
            background-color: $table-data-color;
          }
        }

        // action buttons
        .action-btn {
          //variables
          $btn-size: 18px;
          $btn-sm-size: 16px;


          display: flex;
          flex-direction: row;
          align-items: center;
          grid-gap: 10px;
          gap: 10px;
          height: 30px;

          button {
            min-width: $btn-size;
            min-height: $btn-size;
            width: $btn-size;
            height: $btn-size;
            max-width: $btn-size;
            max-height: $btn-size;
            border-radius: 5px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0;
            color: #fff;
            border: #707070;
            background-color: transparent;

            i {
              display: block;
              font-size: 10px;
              height: 10px;
              width: 10px;
            }

            &.btn-small {
              min-width: $btn-sm-size;
              min-height: $btn-sm-size;
              width: $btn-sm-size;
              height: $btn-sm-size;
              max-width: $btn-sm-size;
              max-height: $btn-sm-size;

              i {
                display: block;
                font-size: 9px;
                height: 9px;
                width: 9px;
              }
            }

            &.bg-blue {
              // download
              background-color: $theme-blue;
              color: #fff;
            }

            &.bg-gray {
              // edit
              background-color: #A1A1A1;
              color: #fff;
            }

            &.bg-yellow {
              // view
              background-color: #F8DA89;
              color: #707070;
            }

            &.bg-red {
              // delete
              background-color: #EF6A65;
              color: #fff;
            }

            &.bg-green {
              // check
              background-color: #C3DA8C;
              color: #fff;

              i {
                font-size: 14px;
                height: 12px;
                width: 14px;
              }
            }

            &.bg-light-gray {
              // with border
              background-color: rgba(118, 118, 118, 0.1);
              color: rgba(118, 118, 118, 0.3);
              border: 1px solid rgba(118, 118, 118, 0.3);

              i {
                font-size: 11px;
                height: 10px;
                width: 10px;
              }
            }
          }
        }

        //to display column data in center
        &.make-center .datatable-body-cell-label {
          display: block;
          text-align: center;
          height: 100%;
        }
      }

      // &.datatable-row-even:not(.active) {
      //   background-color: #fff;
      // }
    }

    .datatable-group-header {
      border-bottom: 1px solid $border-color;
      height: $row-height;
      width: 100% !important;

      >* {
        height: $row-height;
      }

      .group-header-wrapper {
        display: flex;
        align-items: center;
        width: 100% !important;

        >div {
          padding: 0 0.75rem;
          line-height: $row-height;
        }

        .index-column {
          flex: 0 0 70px;
          max-width: 70px;
          text-align: center;
        }

        .group-column {
          cursor: pointer;
          flex: 0 0 270px;
        }

        .checkbox-column {
          flex: 0 0 120px;
          text-align: center;
          height: 100%;
        }
      }
    }
  }

  .datatable-footer {
    background-color: white;
    margin-bottom: 0;
    position: absolute;
    bottom: 0;

    .datatable-footer-inner {
      justify-content: center;
      display: flex !important;
      align-items: center !important;
      width: 100% !important;

      .page-size-control {
        position: absolute;
        left: 40px;
        color: $table-data-color;

        .page-size-dropdown {
          height: 22px;
          padding: 0.2rem;
          line-height: 1.5;
          border-radius: 0.2rem;
          color: $table-data-color;
          font-weight: 600;
          border: none;
          background-color: #d1d4d7;
        }
      }

      .pagination-control {
        .page-link {
          padding: 0.25rem 0.5rem;

          span {
            font-family: sans-serif;
            font-size: 15px;
            line-height: 0.9;
            vertical-align: middle;
          }
        }

        .page-item.active .page-link {
          background-color: $theme-blue !important;
          border-color: $theme-blue !important;
          color: #fff !important;
        }
      }
    }
  }
}

.ngx-datatable.bootstrap.user-permissions-table{
  overflow-x: auto !important;
  .datatable-header,
  .datatable-body{
    width: fit-content !important;
  }
}

.ngx-datatable.bootstrap .datatable-footer .datatable-pager {
  margin: 12px 6px !important;
  white-space: nowrap !important;
}

@media screen and (max-width: 768px) {
  .ngx-datatable.bootstrap {
    .datatable-body {
      .datatable-scroll {
        .datatable-row-wrapper {
          display: table-caption !important;
        }
      }
    }
  }

  .index-column {
    flex: 0 0 50px !important;
    max-width: 50px !important;
  }
}

@media screen and (max-width: 576px) {
  .index-column {
    flex: 0 0 30px !important;
    max-width: 30px !important;
  }
}

//***/ngx datatable***

//***Applicant list***
.ngxdt.applicant-list,
.ngxdt.audit-logs {
  .ngxdt-body .ngx-datatable.bootstrap {
    min-height: calc(100vh - 185px);
    ;
  }

  .datatable-body {
    margin-bottom: 45px !important;
    position: absolute;

    .datatable-body-row {

      &:hover,
      &.active {
        cursor: pointer !important;
        color: $theme-table;
      }
    }
  }
}

.ngxdt.applicant-list,
.ngxdt.audit-logs {
  .datatable-body-cell {
    .datatable-body-cell-label {
      div {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  .datatable-footer {
    margin-bottom: 0px !important;
  }
}

//***/Applicant list***

// user-management table footer //

.ngxdt.user-table,
.ngxdt.stages {
  .datatable-footer {
    overflow: hidden !important;

    .datatable-footer-inner {
      position: relative !important;
      top: 12px !important;
    }

    .page-count {
      color: #707070;
    }

    .datatable-pager {
      text-align: center;
    }

    .datatable-pager a {
      color: #707070 !important;
    }

    .datatable-pager ul li:not(.disabled):hover a,
    .datatable-pager ul li:not(.disabled).active a {
      background-color: $theme-blue !important;
      color: #fff !important;
      font-weight: bold;
    }
  }
}

// user-management table footer //
.hr-red {
  border-bottom: 1px solid $theme-red !important;
}

.hr-light-blue {
  border-bottom: 1px solid $theme-light-blue !important;
}

.hr-gray {
  border-bottom: 1px solid $theme-gray !important;
}

//***Activity list***
.ngxdt.activity-list {
  .ngxdt-body .ngx-datatable.bootstrap {
    margin-bottom: 0 !important;
    min-height: 220px;

    .datatable-header {
      border-bottom: 1px solid #a1a1a1;

      .datatable-header-inner {
        font-weight: 500 !important;
        color: $theme-table;
        border-bottom: none !important;
      }

      .datatable-header-cell {
        padding: 0 0.75rem;
        border-bottom: 1px solid #cecece;
        border-bottom: hidden;

        .datatable-header-cell-label {
          font-size: 11px;
        }
      }
    }

    .datatable-body {
      overflow-y: hidden;

      .datatable-scroll {
        height: 155px !important;
        //min-height: 300px !important;
      }

      .datatable-body-row {
        .datatable-body-cell {
          .datatable-body-cell-label {
            div {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }

        &:hover,
        &.active {
          cursor: pointer;
          color: $theme-table;
        }
      }
    }

    .datatable-footer {
      .datatable-footer-inner {
        .page-size-control {
          left: 0px !important;
        }
      }
    }
  }
}

//***/Applicant list***

.font-weight-500 {
  font-weight: 500;
}

//***dashboard***
.ngxdt.dashboard {
  cursor: pointer !important;
  margin-top: 0 !important;

  .ngxdt-body {
    padding: 0 0 0 0 !important;
    cursor: pointer !important;
  }

  .datatable-header {
    border-bottom: 1px solid #a1a1a1;

    .datatable-header-inner {
      font-weight: 500 !important;
      color: $theme-table;
      border-bottom: none;
    }

    .datatable-header-cell {
      padding: 0 0.75rem;
      border-bottom: none;

      .datatable-header-cell-label {
        font-size: 11px;
      }
    }
  }

  .ngxdt-body .ngx-datatable.bootstrap {
    margin-bottom: 0 !important;
    min-height: 220px;

    .datatable-body {
      height: 100% !important;
      // .datatable-scroll {
      //   // height: 100% !important;
      //   //min-height: 300px !important;
      // }

      .datatable-body-row {
        cursor: pointer !important;

        .datatable-body-cell {
          .datatable-body-cell-label {
            div {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }

        &:hover,
        &.active {
          cursor: pointer;
          color: $theme-table;
        }
      }
    }

    .datatable-footer {
      .datatable-footer-inner {
        .page-size-control {
          left: 0px !important;
        }
      }
    }
  }
}

//***/dashboard***

//***dashboard Tasks***
.ngxdt.dashboard-tasks {
  .ngxdt-body .ngx-datatable.bootstrap {
    margin-bottom: 0 !important;

    .datatable-header {
      .datatable-header-inner {
        font-weight: 600 !important;
        color: $theme-blue;
      }

      .datatable-header-cell {
        padding: 0 0.75rem;
        border-bottom: 1px solid #cecece;
        border-bottom: hidden;

        .datatable-header-cell-label {
          font-size: 11px;
        }
      }
    }

    .datatable-body {
      .datatable-scroll {
        height: 155px !important;
        //min-height: 300px !important;
      }

      .datatable-body-row {
        .datatable-body-cell {
          .datatable-body-cell-label {
            div {
              white-space: nowrap !important;
              overflow: hidden !important;
              text-overflow: ellipsis !important;
            }
          }
        }

        &:hover,
        &.active {
          cursor: pointer;
          color: $theme-table;
        }
      }
    }
  }
}

/////*****Activity Log*******
.ngxdt-body .edit-activity {
  .datatable-body {
    overflow-y: hidden;
  }
}

//***/dashboard Tasks***

//Applicant information edit-button position
.btn-position {
  position: absolute;
  right: 30px;
  top: 10px;
}

//Main Page header
.page-wrapper .page-header .header-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 18px 30px;
  position: relative;
}

.responsive-logo {
  display: none;
}

.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .logo-wrapper,
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .logo-icon-wrapper {
  padding: 10px 30px !important;
  display: flex;
  justify-content: center;
  box-shadow: -9px 0 20px rgb(89 102 122 / 10%);
}

.page-wrapper.compact-wrapper .page-body-wrapper .page-body {
  min-height: calc(100vh - 80px);
  margin-top: 65px !important;
  margin-left: 210px;
}

.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links {
  overflow: hidden !important;
}

//font-size 11px
.theme-font-size {
  *:not(i) {
    font-size: $theme-font-size !important;
  }
}

.f-11 {
  font-size: $theme-font-size !important;
}

//theme-color
.theme-font-color-blue {
  color: $theme-blue !important;
}

.theme-font-color-lightblue {
  color: $theme-light-blue !important;
}

.theme-font-color-gray {
  color: $theme-gray !important;
}

.theme-font-color-red {
  color: $theme-red !important;
}

.theme-font-color-dark {
  color: #707070;
}

// Admin image and Name
.page-wrapper .page-header .header-wrapper .nav-right ul li svg {
  width: 14px;
}

.page-wrapper .page-header .header-wrapper .nav-right .profile-dropdown {
  width: 160px;
  top: 57px;
  left: 12px;
}

.page-wrapper .page-header .header-wrapper .nav-right .profile-dropdown li {
  padding: 5px 0 5px 0 !important;
}

.page-wrapper .page-header .header-wrapper .nav-right .profile-dropdown li svg {
  stroke: $theme-gray;
}

.page-wrapper .page-header .header-wrapper .nav-right .profile-dropdown li span {
  color: $theme-gray;
  font-size: $theme-font-size !important;
}

//checkbox pointer cursor
input[type="checkbox"] {
  cursor: pointer;
}

//notification Page
.page-wrapper .page-header .header-wrapper .nav-right .notification-dropdown li p {
  color: $theme-gray !important;
  opacity: 100% !important;
}

.page-wrapper .page-header .header-wrapper .nav-right .notification-dropdown li p span {
  color: #fff !important;
  opacity: 100% !important;
  font-size: 10px;
}

.page-wrapper .page-header .header-wrapper .nav-right.right-header ul li .profile-media .media-body p {
  font-size: $theme-font-size;
}

//Dashboard table
.table th,
.table td {
  border-top: 0 !important;
  vertical-align: middle !important;
  border-bottom: 1px solid $theme-gray !important;
  color: $theme-table;
  font-family: "Roboto";
}

//Input Border color on disabled and editable
.form-control:disabled {
  color: $theme-table;
  border: 1px solid #ced4da !important;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
}

.form-control {
  color: $theme-table;
  border: 1px solid #a1a1a1;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

select:disabled {
  color: $theme-table;
  background-color: #efefef;
  border-color: 1px solid #ced4da !important;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
}

select {
  color: $theme-table;
  border: 1px solid #a1a1a1 !important;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.f-10 {
  font-size: 10px;
}

//*****top-timeline*****
.top-timeline .timeline-action div {
  line-height: 0 !important;

  >svg {
    max-height: 12px !important;
    max-width: 12px !important;
    margin: 0;
    padding: 0;
    z-index: 1 !important;
    vertical-align: baseline !important;
  }
}

//***** /top-timeline*****

//*****ngbDatePicker*****
input+ngb-datepicker {
  border-radius: 0 !important;
}

//***** /ngbDatePicker*****

// .ngx-datatable {
//   .datatable-body {
//     .progress-linear {
//       display: block;
//       position: relative;
//       width: 100%;
//       height: 2px;
//       padding: 0;
//       margin: 0;
//       position: absolute;
//       .container {
//         display: block;
//         position: relative;
//         overflow: hidden;
//         width: 100%;
//         height: 2px;
//         -webkit-transform: translate(0, 0) scale(1, 1);
//         transform: translate(0, 0) scale(1, 1);
//         background-color: #00ABE8;
//         .bar {
//           transition: all .15s linear;
//           -webkit-animation: query 0.5s infinite cubic-bezier(0.39, 0.575, 0.565, 1);
//           animation: query 0.5s infinite cubic-bezier(0.39, 0.575, 0.565, 1);
//           transition: -webkit-transform .15s linear;
//           transition: transform .15s linear;
//           background-color: #0068A4;
//           position: absolute;
//           left: 0;
//           top: 0;
//           bottom: 0;
//           width: 100%;
//           height: 2px;
//         }
//       }
//     }
//   }
// }
// /**
//  * Progress bar animations
//  */
// @keyframes query {
//   0% {
//     opacity: 1;
//     transform: translateX(35%) scale(0.3, 1);
//   }
//   100% {
//     opacity: 0;
//     transform: translateX(-50%) scale(0, 1);
//   }
// }

// Dashboard Graph CSS

.apexcharts-pie-label,
.apexcharts-datalabels,
.apexcharts-datalabel,
.apexcharts-datalabel-label,
.apexcharts-datalabel-value {
  cursor: default;
  pointer-events: none;
  display: none;
}

.apexcharts-toolbar {
  display: none !important;
}

// Report datepicker and input range

.custom-day {
  text-align: center;
  padding: 0.185rem 0.25rem;
  display: inline-block;
  height: 2rem;
  width: 2rem;
  color: black;
}

.custom-day.focused {
  background-color: #e6e6e6;
}

.custom-day.range,
.custom-day:hover {
  background-color: rgb(2, 117, 216);
  color: #fff;
}

.custom-day.faded {
  background-color: rgba(2, 117, 216, 0.5);
}

.date-input {
  .input-group {
    width: 180px;
    display: inline-flex;
    outline: 1px solid $search-color;
    height: calc(100% - 2px);
    padding: 0;
    border-radius: $border-radius-1;
    font-size: 0.75rem;

    .ngb-dp-month-name {
      font-size: larger;
      height: 2rem;
      line-height: 2rem;
      text-align: center;
      color: black;
    }

    input {
      border: none;
      margin: 0;
      padding: 0 12px !important;
      color: $search-color;
      flex: 1 1 auto;
      width: max-content;
    }

    button {
      margin: 0 0.25rem 0 0;
      padding: 0.25rem 0;
      background-color: transparent;
      outline: none;
      height: 100%;
      border: none;
      border-radius: 0 $border-radius-1 $border-radius-1 0;
      flex: 0 0 auto;
      width: max-content;

      svg {
        color: $search-color;
        padding: 0 0px 3px 0;
      }
    }
  }
}

// Report datepicker and input range

// chart Applicant Progress

.apexcharts-yaxis {
  line {
    stroke: none !important;
  }
}

.apexcharts-gridlines-horizontal {
  line {
    stroke: none !important;
  }
}

.apexcharts-legend.apx-legend-position-bottom.apexcharts-align-center,
.apexcharts-legend.apx-legend-position-top.apexcharts-align-center {
  justify-content: center;
  //display: none;
}

.apexcharts-legend.apx-legend-position-bottom.apexcharts-align-left,
.apexcharts-legend.apx-legend-position-top.apexcharts-align-left,
.apexcharts-legend.apx-legend-position-right,
.apexcharts-legend.apx-legend-position-left {
  justify-content: flex-start;
  top: 2px !important;
}

.box-shadow {
  box-shadow: none !important;
}

.apexcharts-legend-series {
  cursor: pointer;
  line-height: normal;
  margin-bottom: 8px !important;
  margin-top: 8px !important;
}

.m-l-90 {
  margin-left: 90px !important;
}

.m-l-65 {
  margin-left: 65px !important;
}

// chart Applicant Progress end

*::-webkit-scrollbar {
  width: 4px;
  height: 8px;
  cursor: pointer !important;
}

/* Track */
*::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
*::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
*::-webkit-scrollbar-thumb:hover {
  cursor: pointer !important;
  background: #555;
}

.apexcharts-text tspan {
  font-family: "Roboto" !important;
}


.header-progressbar-0 {
  .progress {
    width: 95%;
    position: absolute;
    top: 48%;
    background-color: rgba(239, 239, 239, 1.0) !important;

    .progress-bar {
      background-color: rgba(239, 239, 239, 1.0) !important;
    }
  }
}

.header-progressbar {
  .progress {
    width: 95%;
    position: absolute;
    top: 48%;
    background-image: linear-gradient(to left, rgba(239, 239, 239, 0.7) 0%, rgba(217, 217, 217, 0.7) 30%, rgba(83, 119, 181, 0.7) 70%, rgba(14, 74, 179, 0.7) 100%) !important;

    .progress-bar {
      background-color: rgba(3, 46, 121, 1.0) !important;
    }
  }
}

@media screen and (max-width: 500px) and (min-width: 280px) {

  .header-progressbar {
    .progress {
      width: 95%;
      rotate: 90deg;
      position: absolute;
      left: 7px;
    }
  }
}

.custom-progressbar-0 {
  .progress {
    rotate: 90deg;
    width: 750px;
    position: absolute;
    top: 50%;
    left: -12.5%;
    background-color: rgba(239, 239, 239, 1.0) !important;

    .progress-bar {
      background-color: rgba(239, 239, 239, 1.0) !important;
    }
  }
}

.custom-progressbar {
  .progress {
    rotate: 90deg;
    width: 750px;
    position: absolute;
    top: 50%;
    left: -12.5%;
    background-image: linear-gradient(to left, rgba(239, 239, 239, 0.7) 0%, rgba(217, 217, 217, 0.7) 30%, rgba(83, 119, 181, 0.7) 70%, rgba(14, 74, 179, 0.7) 100%) !important;

    .progress-bar {
      background-color: rgba(3, 46, 121, 1.0) !important;
    }
  }
}

@media screen and (max-width: 576px) and (min-width: 415px) {
  .custom-progressbar .progress {
    rotate: 90deg;
    width: 585px;
    position: absolute;
    top: 50%;
    left: -23.5%;
  }
}

.card.card-full-height {
  min-height: calc(100vh - 112px);
  margin-bottom: 0;
}

/*****custom checkbox*****/
.checkbox {
  &.checkbox-custom {
    $size: 14px;

    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    position: relative;
    line-height: 1.2;

    label {
      margin-top: 0;
      margin-bottom: 0;
      position: relative;
      margin-left: -$size;
      padding-left: $size;
      cursor: pointer;

      &::before {
        content: '';
        position: absolute;
        left: 0;
        margin-left: 0;
        width: $size;
        height: $size;
        border-radius: 2px;
        border-color: $theme-gray-dark;
        transform: translateY(-50%);
        padding: 2px 0;
        font-size: 8px;
      }
    }

    input[type=checkbox] {
      width: $size;
      height: $size;
      margin: 0;

      &:checked {
        &+label {
          &::before {
            content: '\e64c';
            border-color: $theme-blue;
            color: $theme-blue;
          }
        }
      }

      &:disabled {
        &+label {
          opacity: 0.65;

          &::before {
            cursor: not-allowed;
          }
        }
      }
    }

    &.checkbox-dash {
      input[type=checkbox]+label::before {
        content: '\268a';
        font-size: 10px;
        line-height: 0.9;
      }
    }
  }
}

/*****end custom checkbox*****/